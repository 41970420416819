import { registerView } from "@frui.ts/views";
import React from "react";
import InsuranceUploadModalViewModel from "viewModels/company/compliance/questionaire/insuranceUploadModalViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import { Table } from "react-bootstrap";
import { formatDate } from "utils";
import "./insurance.scss";
import { Row, Col, Button } from "@emanprague/veralinkkit";
import FileUpload from "controls/fileUpload";
import LoadingContainer from "views/common/loadingContainer";
import InsuranceStatus from "../components/insuranceStatus";
import InsuranceDocumentStatus from "entities/insuranceDocumentStatus";
import { Input } from "@frui.ts/bootstrap";
import InsuranceApproveReject from "../components/insuranceApproveReject";
import { Pending } from "@emanprague/veralinkkit/dist/Icons";

const PENDING_STATES = [InsuranceDocumentStatus.Pending];

class InsuranceUploadModalView extends ModalView<InsuranceUploadModalViewModel> {
  size: ModalSize = "lg";
  renderFooter(vm: InsuranceUploadModalViewModel) {
    if (vm.isOperator) {
      return <div />;
    }

    return (
      <>
        <Button variant="outline-primary" disabled={vm.busyWatcher.isBusy} onClick={vm.cancel}>
          {vm.translate("cancel")}
        </Button>
        <Button variant="primary" disabled={vm.busyWatcher.isBusy || !vm.model.fileBox.file} onClick={vm.save}>
          {vm.translate("save")}
        </Button>
      </>
    );
  }

  renderBody(vm: InsuranceUploadModalViewModel) {
    return (
      <LoadingContainer loading={vm.busyWatcher.isBusy}>
        {!vm.isOperator && (
          <>
            {PENDING_STATES.includes(vm.firstDoc?.status) && (
              <p>
                <Pending className="safetyPrograms-status__icon" />
                <span>{vm.translate("pendingReview")}</span>
              </p>
            )}

            <h6>{vm.translate("uploadCertificate")}</h6>

            <Row>
              <Col>
                <FileUpload
                  accept={[".pdf"]}
                  onDrop={(files: File[]) => {
                    vm.replaceFile(files[0]);
                  }}
                  localization={{
                    button: vm.translate("upload"),
                    or: vm.translate("dragFile"),
                    types: vm.translate("fileValidation"),
                  }}
                  files={[vm.model.fileBox]}
                  onRemove={vm.removeFile}
                />
              </Col>
            </Row>
          </>
        )}
        <div>
          {!vm.isOperator && vm.model.insuranceRequirements.length !== 0 && (
            <div className="insurance__requirements">
              <h6 className="insurance__title-requirements">{vm.translate("operators")}</h6>

              {vm.model.insuranceRequirements.map(doc => (
                <a key={doc.id} href={doc.url} className="insurance__link" download={doc.name}>
                  {doc.name}
                </a>
              ))}
            </div>
          )}
          <h6 className="insurance__title">{vm.translate("documents")}</h6>
          <Table borderless responsive className="insurance__table">
            <thead>
              <tr>
                <th>{vm.translate("file")}</th>
                <th>{vm.translate("state")}</th>
                <th>{vm.translate("uploaded")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {vm.model.certificates.map(certificate => {
                return (
                  <>
                    <tr key={certificate.id}>
                      <td>
                        <b>{certificate.fileName}</b>
                      </td>
                      <td>
                        <InsuranceStatus
                          translate={vm.translate}
                          status={certificate.status}
                          rejectedMessage={certificate.rejectedMessage}
                        />
                      </td>
                      <td>{formatDate(certificate.uploadedAt)}</td>
                      <td>
                        <a href={certificate.fileUrl} download={certificate.fileName}>
                          {vm.translate("download")}
                        </a>
                      </td>
                    </tr>
                    <InsuranceApproveReject
                      visible={vm.isOperator && certificate.status === InsuranceDocumentStatus.Pending}
                      certificate={certificate}
                      submitReview={vm.submitDocumentReview}
                      tg={vm.translate}>
                      <Input
                        as="textarea"
                        type="text"
                        target={vm.rejectMessages}
                        property={certificate.id}
                        placeholder={vm.translate("rejectMessagePlaceholder")}
                      />
                    </InsuranceApproveReject>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </LoadingContainer>
    );
  }
}

registerView(InsuranceUploadModalView, InsuranceUploadModalViewModel);
