import { Button, Col, LoaderOverlay, Row } from "@emanprague/veralinkkit";
import { ZoomIn } from "@emanprague/veralinkkit/dist/Icons";
import { registerView } from "@frui.ts/views";
import classnames from "classnames";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { formatDate } from "utils";
import CredentialOverviewViewModel from "viewModels/worker/credentials/credentialOverviewViewModel";
import ModalView, { ModalSize } from "views/common/modalView";
import CredentialStatus from "./components/credentialStatus";
import "./credentials.scss";

class OverviewCredentialModalView extends ModalView<CredentialOverviewViewModel> {
  size: ModalSize = "xl";

  renderBody(vm: CredentialOverviewViewModel) {
    const ta = vm.localization.translateAttribute;

    return (
      <LoaderOverlay variant="light" loading={vm.busyWatcher.isBusy}>
        <Row className="credential-overview">
          <Col xs={12} lg={5} className="credential-overview__photo">
            {vm.photoUrl && <img src={vm.photoUrl} className="credential-overview__photo-pic" alt={vm.translate("photoAlt")} />}
            {vm.photoUrl && (
              <a className="magnify" rel="noopener noreferrer" href={vm.photoUrl} target="_blank">
                <ZoomIn className="magnify__icon" />
              </a>
            )}
            {!vm.photoUrl && <p className="credential-overview__empty-photo text-muted">{vm.translate("noImage")}</p>}
          </Col>
          <Col xs={12} lg={7}>
            <CredentialStatus vm={vm} />

            <div className="credential-renew">
              <h4 className="credential-renew__title">{vm.translate("whereRenew")}</h4>

              <b className="credential-renew__entity">{vm.trainingEntity?.name}</b>
              <p>
                <a target="_blank" rel="noopener noreferrer" className="credential-renew__link" href={vm.trainingEntity?.webPage}>
                  {vm.trainingEntity?.webPage}
                </a>
              </p>
            </div>

            <ListGroup variant="flush">
              <ListGroup.Item>
                <Row className="credential-row">
                  <Col xs={12} md={6}>
                    <span className="credential-row__name">{ta("credential", "id")}</span>
                  </Col>
                  <Col xs={12} md={6} className="credential-row__value">
                    {vm.credential.externalId}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="credential-row">
                  <Col xs={12} md={6}>
                    <span className="credential-row__name">{ta("credential", "nameOnCredential")}</span>
                  </Col>
                  <Col xs={12} md={6} className="credential-row__value">
                    {vm.credential.holderName}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="credential-row">
                  <Col xs={12} md={6}>
                    <span className="credential-row__name">{ta("credential", "trainingEntityId")}</span>
                  </Col>
                  <Col xs={12} md={6} className="crednetial-row__value">
                    {vm.trainingEntity?.name}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row className="credential-row">
                  <Col xs={12} md={6}>
                    <span className="credential-row__name">{vm.translate("valid")}</span>
                  </Col>
                  <Col xs={12} md={6} className="credential-row__value">
                    {formatDate(vm.credential.validityFrom)} - {formatDate(vm.credential.validityTo)}
                  </Col>
                </Row>
              </ListGroup.Item>
              {vm.credential.verifiedAt && (
                <ListGroup.Item>
                  <Row className="credential-row">
                    <Col xs={12} md={6}>
                      <span className="credential-row__name">{ta("credential", "lastUpdate")}</span>
                    </Col>
                    <Col xs={12} md={6} className="credential-row__value">
                      {formatDate(vm.credential.verifiedAt)}
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      </LoaderOverlay>
    );
  }

  renderFooter(vm: CredentialOverviewViewModel) {
    const deleteClassnames = classnames("credential-footer__button", {
      "is-disabled": vm.isDisabled,
    });
    return (
      <div className="credential-footer">
        <Button
          className={deleteClassnames}
          variant="outline-danger"
          disabled={vm.busyWatcher.isBusy || vm.isDisabled}
          onClick={vm.deleteCredential}>
          {vm.translate("delete")}
        </Button>

        <Button disabled={vm.isDisabled} className={deleteClassnames} variant="primary" onClick={vm.editCredential}>
          {vm.translate("edit")}
        </Button>
      </div>
    );
  }
}

registerView(OverviewCredentialModalView as any, CredentialOverviewViewModel);
