import { Transform } from "class-transformer";
import { formatISO } from "date-fns";
import { observable } from "mobx";
import { parseDateOnly } from "utils";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class WorkerProfileExperience {
  /**
   * Experience ID (read-only)
   */
  id?: string;

  /**
   * Company
   */
  @observable
  company!: string;

  /**
   * Employee ID. `company` and `employeeId` are used for OQSG credential matching
   */
  @observable
  employeeId?: string;

  /**
   * Your role
   */
  @observable
  role!: string;

  /**
   * From date
   */
  @observable
  @Transform(value => (value ? parseDateOnly(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  fromDate?: Date;

  /**
   * To date
   */
  @observable
  @Transform(value => (value ? parseDateOnly(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  toDate?: Date;

  /**
   * Is this job current job?
   */
  @observable
  currentJob!: boolean;

  /**
   * Description
   */
  @observable
  description?: string;

  static ValidationRules = {
    company: { required: true },
    role: { required: true },
    fromDate: { format: "date", required: true, notBeOlderThan: { min: 1950 } },
    toDate: { format: "date", requiredIf: { condition: (ent: { currentJob: boolean }) => !ent.currentJob } },
    currentJob: { required: true },
  };
}
