import { Transform } from "class-transformer";
import { formatISO } from "date-fns";
import { observable } from "mobx";
import { parseDateOnly } from "utils";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyEmployeesPendingRecord {
  /**
   * WorkerImportPending.id
   */
  id!: string;

  /**
   * Worker's email
   */
  @observable
  email!: string;

  @observable
  employeeId?: string;

  @observable
  @Transform(value => (value ? parseDateOnly(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  dateCreated!: Date;

  @observable
  firstName!: string;

  @observable
  lastName!: string;

  @observable
  mobile!: string;

  @observable
  socialSecurityNumber!: string;

  static ValidationRules = {
    id: { required: true },
    email: { required: true },
    dateCreated: { format: "date", required: true },
    firstName: { required: true },
    lastName: { required: true },
    mobile: { required: true },
    socialSecurityNumber: { required: true },
  };
}
