import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import SubModuleViewModel from "viewModels/company/myConnections/subModuleViewModel";
import { Card, Dropdown, Button } from "@emanprague/veralinkkit";
import { Menu } from "@emanprague/veralinkkit/dist/Icons";
import "./index.scss";
import { formatDate } from "utils";
import Pager from "controls/pager";
import { ConnectionStatus } from "./components/connectionStatus";
import LoadingContainer from "views/common/loadingContainer";
import classnames from "classnames";

const subModuleView: ViewComponent<SubModuleViewModel> = observer(({ vm }) => {
  const tg = (code: string, params = {}) => vm.localization.translateGeneral(`myConnections.card.${code}`, params);
  if (vm.activeChild) {
    return <View vm={vm.activeChild} />;
  }
  return (
    <>
      <h4 className="company__header">{vm.name}</h4>
      <div className="my-connections">
        <LoadingContainer loading={vm.busyWatcher.isBusy}>
          {vm.items?.map(connection => {
            const classes = classnames("my-connections__invite", {
              "my-connections__operator-data": vm.isOperator,
              "cursor-pointer": connection.accepted,
            });
            return (
              <Card className="mb-4" key={connection.connectionId}>
                <Card.Header>
                  {!connection.accepted && (
                    <>
                      <p className={classes}>
                        <span>{connection.companyName}</span>
                        <span className="text-normal">{vm.isOperator ? tg("pendingLink") : tg("linkWith")}</span>
                      </p>
                      {vm.isOperator && (
                        <div className="my-connections__actions">
                          <Dropdown
                            className="employeeCard__options"
                            title={<Menu fontSize={24} color="#888F91" />}
                            variant="link"
                            id="options">
                            <Dropdown.Item onClick={() => vm.decline(connection.connectionId as string)}>
                              {tg("unlinkCancel")}
                            </Dropdown.Item>
                          </Dropdown>
                        </div>
                      )}
                    </>
                  )}
                  {connection.accepted && (
                    <>
                      <div
                        className="my-connections__title cursor-pointer"
                        onClick={() => vm.openDetail(connection.connectionId as string)}>
                        <h5>{connection.companyName}</h5>
                        {connection.acceptedAt && (
                          <p className="text-muted">{tg("since", { value: formatDate(connection.acceptedAt) })}</p>
                        )}
                      </div>
                      <div className="my-connections__actions">
                        <ConnectionStatus
                          isOperator={vm.isOperator}
                          visitDetail={() => vm.openDetail(connection.connectionId as string)}
                          tg={tg}
                          connection={connection}
                        />

                        <Dropdown
                          className="employeeCard__options"
                          title={<Menu fontSize={24} color="#888F91" />}
                          variant="link"
                          id="options">
                          {connection.accepted && (
                            <Dropdown.Item onClick={() => vm.sendMessage(connection)}>{tg("sendMessage")}</Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => vm.decline(connection.connectionId as string)}>
                            {connection.accepted ? tg("unlink") : tg("remove")}
                          </Dropdown.Item>
                        </Dropdown>
                      </div>
                    </>
                  )}
                </Card.Header>
                {vm.canConnect(connection) && (
                  <Card.Footer>
                    <Button
                      variant="outline-primary"
                      className="accept-button"
                      onClick={() => vm.accept(connection.connectionId as string)}>
                      {tg("accept")}
                    </Button>
                    <Button
                      variant="outline-danger"
                      className="decline-button"
                      onClick={() => vm.cancel(connection.connectionId as string)}>
                      {tg("decline")}
                    </Button>
                  </Card.Footer>
                )}
              </Card>
            );
          })}

          {vm.canLoadData && <Pager paging={vm.currentPaging} onPageChanged={vm.changePage} />}
        </LoadingContainer>
      </div>
    </>
  );
});

registerView(subModuleView, SubModuleViewModel);
