import { Col, Row } from "@emanprague/veralinkkit";
import { CredentialsOk, Photo } from "@emanprague/veralinkkit/dist/Icons";
import classnames from "classnames";
import { CredentialPhoto } from "entities/credential";
import React from "react";
import { formatDate } from "utils";
import { expiredState } from "../utils";

type Props = {
  onClick: () => void;
  item: CredentialPhoto;
  tg: (key: string) => string;
};

const CredentialItem: React.FC<Props> = ({ item, onClick, tg }) => {
  const currentState = expiredState(item.validityFrom, item.validityTo);
  const classNames = classnames("credential-item", {
    "is-good": currentState === "isGood",
    "is-expired": currentState === "isExpired",
    "will-expire": currentState === "willExpire",
  });

  return (
    <Row className={classNames} onClick={onClick}>
      <Col md={4} xs={8}>
        <p className="credential-item__name">{item.credentialName}</p>
      </Col>
      <Col md={6} xs={12} className="credential-item__description">
        <p className="credential-item__secondary">{item.trainingEntity?.name}</p>
        <div className="credential-item__secondary">
          {formatDate(item.validityFrom)} - {formatDate(item.validityTo)}
          {item.verifiedAt && (
            <div>
              <b>{tg("updated")}:</b>&nbsp;{formatDate(item.verifiedAt)}
            </div>
          )}
        </div>
      </Col>

      <Col md={2} xs={4} className="justify-content-end d-flex">
        <div className="credential-item__actions">
          {item.photoUrl && <Photo width={32} height={32} />}
          {item.verified && <CredentialsOk className="credential-item__sign" width={32} height={32} />}
        </div>
      </Col>
    </Row>
  );
};

export default CredentialItem;
