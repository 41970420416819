import { Transform } from "class-transformer";
import { formatISO } from "date-fns";
import { observable } from "mobx";
import { parseDateOnly } from "utils";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompanyProjectStats {
  /**
   * read-only
   */
  id?: string;

  @observable
  name?: string;

  @observable
  @Transform(value => (value ? parseDateOnly(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  startDate?: Date;

  @observable
  @Transform(value => (value ? parseDateOnly(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  endDate?: Date;

  static ValidationRules = {
    startDate: { format: "date" },
    endDate: { format: "date" },
  };
}
